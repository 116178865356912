import {CommonModule} from '@angular/common';
import {Component, computed, ViewEncapsulation} from '@angular/core';
import {doc, docData, Firestore} from '@angular/fire/firestore';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {Q9TransformPipe, SessionService} from '@q9elements/ui-kit/common';
import {find, get} from 'lodash';
import {map, Observable} from 'rxjs';

import {LastVisitedTeams} from '../../models/space-navigation';
import {NavigationService} from '../../services/navigation.service';

@Component({
  selector: 'q9ma-space-navigation',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    Q9TransformPipe,
    MatInputModule
  ],
  templateUrl: './space-navigation.component.html',
  styleUrls: ['./space-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpaceNavigationComponent {
  teamName = this.initTeamNameObserver();
  lastVisitedTeams$: Observable<LastVisitedTeams> = this.initLastVisitedTeamsObserver();

  constructor(
    private firestore: Firestore,
    private sessionService: SessionService,
    private navigationService: NavigationService
  ) {}

  changeSpace(index: number) {
    if (this.sessionService.teamIndex() === index) {
      return void 0;
    }

    return this.navigationService.navigateToSpace(index);
  }

  navigateToSpaces() {
    return this.navigationService.navigateToModule('teams', {ref: 'my'});
  }

  getIconName(opened: boolean) {
    return opened ? 'arrow_up' : 'arrow_down';
  }

  private initTeamNameObserver() {
    const equal = (previous: string, next: string) => !next || previous === next;
    const computation = () => get(this.sessionService.user(), 'userInTeam.team.name', '');

    return computed<string>(computation, {equal});
  }

  private initLastVisitedTeamsObserver() {
    const ref = doc(this.firestore, `users/${this.sessionService.userId()}`).withConverter({
      fromFirestore: (snapshot): string[] => snapshot.get('lastVisitedTeams') || [],
      toFirestore: () => ({})
    });

    return docData(ref).pipe(
      map((ids = []) => {
        const teams = this.sessionService.user()?.teams || [];

        if (!ids.length || !teams.length) {
          return [];
        }

        const defaultTeam = find(teams, {defaultTeam: true})!;
        const defaultTeamId = defaultTeam.team.id;

        // Ensure the default team is always the first in the list
        if (!ids.includes(defaultTeamId)) {
          ids.unshift(defaultTeamId);
        }

        // Convert ids to array of LastVisitedTeams
        return ids.reduce<LastVisitedTeams>((acc, id) => {
          const userTeam = find(teams, ({team}) => team.id === id);

          if (!userTeam || id === this.sessionService.teamId()) {
            return acc;
          }

          acc.push({index: userTeam.teamIndex, name: userTeam.team.name, id: userTeam.team.id});

          return acc;
        }, []);
      })
    );
  }
}
