<div class="space-navigation-wrapper">
  <button mat-flat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
    <mat-icon svgIcon="q9-horizontal"></mat-icon>
    {{ teamName() }}

    <mat-icon iconPositionEnd [svgIcon]="menuTrigger.menuOpen | transform: getIconName"></mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="changeSpace(space.index)"
      *ngFor="let space of lastVisitedTeams$ | async">
      {{ space.name }}
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="navigateToSpaces()">Manage spaces</button>
  </mat-menu>
</div>
