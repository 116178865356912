import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  DialogWidth,
  PermissionsService,
  SessionService,
  REPORT_MODAL_TYPE
} from '@q9elements/ui-kit/common';
import {ReportModalComponent} from '@q9elements/ui-kit/components';

@Injectable()
export class CommonWrapperDialogService {
  constructor(
    private dialog: MatDialog,
    private sessionService: SessionService,
    private permissionsService: PermissionsService
  ) {}

  public openReportDialog(modalType: REPORT_MODAL_TYPE) {
    const userInTeam = this.sessionService.user()!.userInTeam!;
    const currentTeam = {
      _id: this.sessionService.teamId(),
      name: userInTeam.team.name,
      planType: userInTeam.team.plan.type,
      teamIndex: this.sessionService.teamIndex(),
      trialExpired: !this.permissionsService.isProActive()
    };
    const reportType = 'requirement';
    const defaultFilter = {priority: null, summary: '', tags: []};
    const anchorTarget = '_blank';

    this.dialog.open(ReportModalComponent, {
      width: DialogWidth.LG_XL,
      data: {
        currentTeam,
        modalType: modalType,
        generalReportType: reportType,
        defaultFilter,
        anchorTarget
      }
    });
  }
}
